import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import Gallery from "../components/Gallery";

const PhotoGalleryWrapper = styled.div`
  @media screen and (min-width: 769px) {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 768px;
  }
  .photoGallery__item {
    margin: 3px 0;
    width: 100%;
  }
  .gallery-title {
    padding: 20px 0 10px;
    font-size: 1.5rem;
  }
`;

const PhotoGalleryItem = styled.div`
  .images {
    overflow: hidden;
  }
`;

const PhotoGalleryPageTemplate = ({ title, photoGalleries }) => (
  <div className="section">
    <PhotoGalleryWrapper className="columns">
      <div className="column is-12">
        <div className="title-wrapper">
          <h2 className="title has-text-weight-bold is-bold-light">{title}</h2>
        </div>
        {photoGalleries.length > 0 &&
          photoGalleries.map((photoGallery, i) => {
            const images = photoGallery.images
              .filter(image => !!image.image)
              .map((image, i) => {
                let photo = { ...image.image.childImageSharp };
                photo = {
                  ...photo,
                  title: image.title
                };
                return photo;
              });
            return (
              <PhotoGalleryItem className="photoGallery__item" key={i}>
                <>
                  <h3 className="gallery-title">
                    {photoGallery.photoGalleryTitle}
                  </h3>
                  <div className="images">
                    <Gallery
                      title={photoGallery.photoGalleryTitle}
                      slug=""
                      images={images}
                      itemsPerRow={[3]}
                    ></Gallery>
                  </div>
                </>
              </PhotoGalleryItem>
            );
          })}
      </div>
    </PhotoGalleryWrapper>
  </div>
);

const PhotoGalleryPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <PhotoGalleryPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        photoGalleries={frontmatter.photoGalleries}
      />
    </Layout>
  );
};

PhotoGalleryPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default PhotoGalleryPage;

export const photoGalleriesPageQuery = graphql`
  query PhotoGalleryPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        photoGalleries {
          images {
            image {
              childImageSharp {
                thumb: gatsbyImageData(
                  width: 400
                  height: 400
                  placeholder: BLURRED
                )
                full: gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            title
          }
          photoGalleryDescription
          photoGalleryTitle
        }
      }
    }
  }
`;
