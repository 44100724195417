import React from "react";
import PropTypes from "prop-types";
import ImageGallery from '@browniebroke/gatsby-image-gallery'

const Gallery = ({ title, images, itemsPerRow: itemsPerRowByBreakpoints }) => {
  return (
    <ImageGallery images={images} />
  );
};

Gallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      full: PropTypes.shape({
        aspectRatio: PropTypes.number,
        base64: PropTypes.string,
        sizes: PropTypes.string,
        src: PropTypes.string,
        srcSet: PropTypes.string
      }),
      thumb: PropTypes.shape({
        aspectRatio: PropTypes.number,
        base64: PropTypes.string,
        sizes: PropTypes.string,
        src: PropTypes.string,
        srcSet: PropTypes.string
      })
    })
  ),
  itemsPerRow: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number)
  ]),
  title: PropTypes.string
};

export default Gallery;
